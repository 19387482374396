/* App.css */
.App {
  text-align: center;
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #555555;
  height: 200px;
  padding: 10px 20px;
  color: white;
}

.App-logo {
  height: 150px; /* Kein Leerzeichen hier */
  margin-right: 20px;
}

.App-title {
  font-size: 1.5rem;
}

.main-page {
  padding: 20px;
}

.artists-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.artist {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
}

.artist h2 {
  margin-bottom: 10px;
}
